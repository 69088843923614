<template>
  <div id="blog-article" v-loading="loading">
    <div class="blog-article-warp">
      <div class="blog-article-body">
        <div class="blog-article-title">{{ article.title }}</div>
        <div class="blog-article-info">
          <span
            @click="switchTime"
            class="blog-article-time">
            <i :class="showTimeIcon"></i>{{ showContent }}
            </span>
          <span
            @click="toCategory(article.category.categoryId)"
            class="blog-article-category">
            <i class="el-icon-myblog-folder"></i>
            {{ article.category && article.category.name }}
          </span>
          <span><i class="el-icon-myblog-browse"></i>阅读了 {{ article.pageviews }} 次</span>
        </div>
        <div class="blog-article-sub-message">
          {{ article.subMessage }}
        </div>
        <markdown-preview :contents="article.contents || ''" />
      </div>
      <div class="blog-article-tags">
        <span><i class="el-icon-myblog-discount"></i></span>
        <a
          v-for="(tag, index) in article.tags"
          :key="index"
          @click="toTag(tag.tagId)">
          #{{ tag.name }}
        </a>
      </div>
      <div class="blog-article-money-warp">
        <div
          class="blog-article-money-button"
          @click="showQrcode = !showQrcode">
          赞赏支持
        </div>
        <el-collapse-transition>
          <div
            class="blog-article-qrcode-wrap"
            v-show="showQrcode">
            <img class="blog-article-qrcode" v-lazy="WeChatPayQRcode" alt="微信赞赏">
            <img class="blog-article-qrcode" v-lazy="AliPayQRcode" alt="支付宝赞赏">
          </div>
        </el-collapse-transition>
      </div>
      <hr v-if="article.prevArticle && article.nextArticle">
      <div class="blog-article-footer">
        <div
          v-if="article.prevArticle"
          @click="readArticle(article.prevArticle.articleId)"
          class="blog-article-prev">
          <i class="el-icon-myblog-back"></i>
          {{ article.prevArticle.title }}
        </div>
        <div
          v-if="article.nextArticle"
          @click="readArticle(article.nextArticle.articleId)"
          class="blog-article-next">
          {{ article.nextArticle.title }}
          <i class="el-icon-myblog-more"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blog } from '@/utils/config'

import MarkdownPreview from '@/components/MarkdownPreview'

export default {
  name: 'blog-article',
  components: {
    MarkdownPreview
  },
  data () {
    return {
      showQrcode: false,
      WeChatPayQRcode: blog.WeChatPayQRcode,
      AliPayQRcode: blog.AliPayQRcode,
      article: {},
      showPublishTime: true,
      showContent: '',
      showTimeIcon: 'el-icon-myblog-calendar',
      loading: true
    }
  },
  watch: {
    '$route.params' () {
      this.getArticle()
    }
  },
  methods: {
    switchTime () {
      this.showPublishTime = !this.showPublishTime
      if (this.showPublishTime) {
        this.showContent = '发布于 ' + this.article.publishTime
        this.showTimeIcon = 'el-icon-myblog-calendar'
      } else {
        this.showContent = '编辑于 ' + this.article.modifiedTime
        this.showTimeIcon = 'el-icon-myblog-edit'
      }
    },
    readArticle (articleId) {
      if (articleId) {
        this.loading = true
        this.$router.push({
          name: 'Article',
          params: { articleId: articleId }
        })
      }
    },
    toCategory (categoryId) {
      this.$router.push({
        name: 'CategoryArticleList',
        params: { categoryId: categoryId }
      })
    },
    toTag (tagId) {
      this.$router.push({
        name: 'TagArticleList',
        params: { tagId: tagId }
      })
    },
    getArticle () {
      if (this.$route.params.articleId) {
        this.$http({
          url: this.$api.BLOG_GET_ARTICLE_URL,
          method: 'GET',
          params: { articleId: this.$route.params.articleId }
        }).then(response => {
          if (response.status === 'success') {
            this.article = response.data.article
            this.showContent = '发布于 ' + this.article.publishTime
            this.loading = false
          } else {
            this.$message.error('服务器好像出错了...')
            this.loading = false
          }
        }).catch(() => {
          this.$message.error('哎呀...网络出错了，刷新一下吧')
          this.loading = false
        })
      } else {
        this.$router.push({ name: 'HomeIndex' })
      }
    }
  },
  created () {
    this.getArticle()
  }
}
</script>

<style lang="stylus" scoped>
#blog-article
  position: relative
  width: 100%
  .blog-article-warp
    position: relative
    animation: show .8s
    padding: 30px 30px 0 30px
    width: 100%
    @media (max-width: 768px)
      padding: 30px 15px
    box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
    .blog-article-body
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      .blog-article-title
        font-size: 22pt
        @media (max-width: 768px)
          font-size: 18pt
        font-weight: bold
      .blog-article-info
        font-size: 10pt
        @media (max-width: 768px)
          font-size: 8pt
        margin: 20px 0
        color: #5c5c5c
        display: flex
        flex-direction: row
        justify-content: center
        flex-wrap: wrap
        span
          margin-right: 20px
          &:last-child
            margin-right: 0
          i
            margin-right: 5px
          @media (max-width: 450px)
            width: 100%
            margin: 5px 0
            padding: 0
            i
              margin-right: 5px
      .blog-article-sub-message
        color: #5c5c5c
        font-size: 10pt
        margin: 5px 20px
      .blog-article-time
        cursor: pointer
      .blog-article-category
        cursor: pointer

  .blog-article-money-warp
    width: 100%
    padding: 20px 30px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .blog-article-money-button
      margin: 20px
      padding: 10px 24px
      background-color: #ea3264
      border-radius: 5px
      color: #ffffff
      cursor: pointer
      transition: background-color .5s
      &:hover
        background-color: #ff3366
    .blog-article-qrcode-wrap
      width: 100%
      margin-bottom: 10px
      .blog-article-qrcode
        margin: 0 60px
        width: 240px
        height: 240px
        @media (max-width: 900px)
          margin: 0 30px
          width: 180px
          height: 180px
        @media (max-width: 690px)
          margin: 10px 20px

  .blog-article-tags
    text-align: left
    margin: 30px 0 0 0
    line-height: 20px
    span
      margin-right: 5px
    a
      cursor: pointer
      font-size: 10pt
      margin-right: 10px
      transition: all .3s
      &:hover
        font-size: 11pt
      &:last-child
        margin-right: 0

  .blog-article-footer
    width: 100%
    padding: 20px 0
    display: flex
    flex-direction: row
    font-size: 11pt
    font-weight: 600
    color: #4b4b4b
    @media (max-width: 768px)
      padding: 20px 0 0 0
    @media (max-width: 500px)
      display: block
    .blog-article-prev
      text-align: left
      @media (max-width: 500px)
        padding-bottom: 10px
    .blog-article-next
      text-align: right
      @media (max-width: 500px)
        padding-top: 10px
    .blog-article-prev,
    .blog-article-next
      flex: 1
      cursor: pointer
      &:hover
        color: lighten(#4b4b4b, 30%)

  hr
    border: 0
    height: 1px
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.01))
</style>
